import React from 'react';

// components
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

// styled
import Superscript from '../components/styled/Superscript';
import DateText from '../components/styled/DateText';
import Section from '../components/styled/Section';
import Container from '../components/styled/Container';
import Wrapper from '../components/styled/Wrapper';
import Row from '../components/styled/Row';
import Column from '../components/styled/Column';

// sections
import MailingList from '../components/sections/MailingList';

// assets
import James from '../assets/James-Shore.jpg';

class Training extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Training" />
        <Nav selected="/training" />

        <Section>
          <Wrapper>
            <Superscript center>Training</Superscript>
            <DateText>September 11, 2019, Toronto</DateText>
            <DateText>- OR -</DateText>
            <DateText>September 13, 2019, Toronto</DateText>
          </Wrapper>
        </Section>
        <Section noPaddingTop squareDetail="right" dotDetail="left">
          <Wrapper>
            <Superscript smaller center>
              James Shore
            </Superscript>

            <Row style={{ marginTop: 60 }}>
              <Column>
                <div style={{ padding: '0 40px' }}>
                  <img src={James} />
                </div>
              </Column>
              <Column>
                <p>
                  <a href="https://www.jamesshore.com/">James Shore</a> teaches, writes, and consults on Agile development processes with an emphasis on technical excellence.
                  He test-drove his first web application in 2000 and hasn’t stopped since.
                  He is a recipient of the Agile Alliance's Gordon Pask Award for Contributions to Agile Practice,
                  co-author of <a href="http://shop.oreilly.com/product/9780596527679.do">The Art of Agile Development (O'Reilly)</a>, host of <a href="https://www.letscodejavascript.com/">Let's Code: Test-Driven JavaScript</a>, and co-creator of the <a href="https://martinfowler.com/articles/agileFluency.html">Agile Fluency™ Model</a>.
                  InfoQ has named him one of the “most influential people in Agile.”
                </p>
              </Column>
            </Row>

          </Wrapper>
        </Section>

        <Section noPaddingTop>
          <Wrapper>
            <Superscript smaller center>
              Workshop: Faster, More Effective Test-Driven Development
            </Superscript>
            <p>
              Are your tests slow and brittle? Do you get a sinking feeling when you need to refactor? Do you worry that your unit tests aren’t actually testing anything important? If so, you could benefit from writing tests that don’t use mock objects.
                </p>
            <p>
              In this workshop, you’ll use Node.js to explore a cutting-edge approach to test-driven development (TDD) that doesn’t need mocks or end-to-end tests. We’ll re-examine test-driven development from first principles to create tests that are fast, effective, and easy to write. We’ll tackle real-world problems such as testing infrastructure, evolving mock-free designs from scratch, and working with existing mock-based designs.
                </p>
            <p>
              You’ll learn the core principles of testing without mocks, the tradeoffs involved, what’s easier, and what’s harder. Because this course re-examines test-driven development from a new perspective, it’s suitable for both TDD beginners and experts. You’ll leave with a new bag of tricks for your toolkit and you’ll be ready to choose between a mock-based or mock-free approach depending on the needs of your code.
                </p>
            <p>
              Target audience: Experienced programmers. Basic familiarity with JavaScript and Node.js. No prior TDD experience is needed, but basic familiarity with testing frameworks is recommended.
                </p>
            <p>
              Pre-requisites for attendees:
                  <ul>
                <li>Laptop with Wifi</li>
                <li>Node.js installed</li>
                <li>git installed</li>
              </ul>
            </p>
            <p>
              Max attendees: 30
            </p>
            <p>
              Length: 1 day
            </p>
            <Superscript smaller center>
              Location
            </Superscript>
            <p>
              The training will be held at the <a href="https://goo.gl/maps/BSKXZDLDDSwtWVZ68">Cisco Toronto Innovation Centre, 88 Queen's Quay West, Suite 2900</a>. &nbsp;
            </p>
            <p>
              <b>Note that this is different from the main conference venue!</b>
            </p>
            <p>
              <a href="https://drive.google.com/open?id=0B4JoTt-NyIq5SEhaTWs3MkdWbTRfQUJWZnpwTWYxUlVRckpJ">Detailed directions are here</a>.
            </p>
            <Superscript smaller center>
              Time
            </Superscript>
            <p>
              The training begins at 9 AM <b>sharp</b> and goes until 5 PM. 
            </p>
            <p>
              There will be a one-hour lunch break, and morning and afternoon snack and coffee breaks. We will provide a light breakfast (e.g. bagels &amp; cream cheese or something similar), plus lunch and snacks.
            </p>
          </Wrapper>
        </Section>

        <MailingList />

        <Footer />
      </Container>
    );
  }
}

export default Training;
